import { createStore } from "vuex";

const store = createStore({
  state: {
    isAuthenticated: !!sessionStorage.getItem("access_token"),
    user: JSON.parse(sessionStorage.getItem("user")) || null,
    csrfToken: sessionStorage.getItem("csrf_token") || null,
  },
  mutations: {
    login(state, { access_token, csrf_token, user }) {
      state.isAuthenticated = true;
      state.user = user;
      state.csrfToken = csrf_token; // Устанавливаем csrfToken
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("csrf_token", csrf_token); // Сохраняем csrfToken в sessionStorage
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.csrfToken = null; // Обнуляем csrfToken при выходе пользователя
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("csrf_token"); // Удаляем csrfToken из sessionStorage
      console.log("logout");
    },
  },
  actions: {
    setUser({ commit }, user) {
      // Вызываем мутацию для установки пользователя
      commit("login", user);
    },
    logoutUser({ commit }) {
      // Вызываем мутацию для выхода пользователя
      commit("logout");
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    currentUser(state) {
      return state.user;
    },
    csrfToken(state) {
      return state.csrfToken; // Геттер для получения csrfToken из хранилища
    },
  },
});

export default store;
